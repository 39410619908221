<template>
  <section>
    <VCard class="soloMsg elevation-20">
      <div class="msg_top_btn">
        <VBtn
          icon
          flat
          color="white"
          @click="close"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </div>
      <div class="msg_image">
        <img
          src="../assets/icons/chat.svg"
          alt="Message"
        >
      </div>
      <div class="msg_no">
        +91-{{ phone }}
        <span>1 SMS Credit Will Be Used</span>
      </div>
      <section class="msg_container">
        <div class="pt-3">
          <p>{{ msg }}</p>
        </div>

        <div>
          <VTextField
            v-model="msg"
            autofocus
            solo
            flat
            label="Message"
            append-icon="send"
            maxlength="160"
            counter
            @click:append="toSend = true"
          />
        </div>
      </section>
    </VCard>
    <VDialog
      v-model="toSend"
      width="300"
      persistent
    >
      <ConfirmAction
        @confirm="send()"
        @close="toSend = false"
      />
    </VDialog>
    <VDialog
      v-model="isSending"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'sending'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Sending SMS, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'sent'"
        class="notification_card elevation-20"
      >
        <p>Sent</p>
        <span>SMS Sent Successfully</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'info'"
        class="notification_card elevation-20"
      >
        <p>Info</p>
        <span>SMS delivery can sometimes take upto 10 minutes depending on user's network</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="gotIt()"
          >
            Got It
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../conf/api';
import ConfirmAction from './confirmAction.vue';

export default {
  components: {
    ConfirmAction,
  },
  props: ['phone', 'title'],
  data: () => ({
    toSend: false,
    isSending: false,
    loadStatus: '',
    msg: '',
    dialog: false,
    status: null,
    msgDate: moment().format('DD-MM-YYYY'),
  }),
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    gotIt() {
      this.isSending = false;
      setTimeout(() => {
        this.toSend = false;
      }, 200);
      setTimeout(() => {
        this.$emit('close');
      }, 400);
    },
    close() {
      this.$emit('close');
      this.msg = '';
    },
    send() {
      const smsSchema = {
        title: this.title,
        phNumber: [this.phone],
        message: this.msg,
        smsType: 'Solo',
        date: this.msgDate,
      };
      this.isSending = true;
      this.loadStatus = 'sending';
      axios.post(`${api}/sms/send`, smsSchema).then(() => {
        this.loadStatus = 'sent';
        setTimeout(() => {
          this.loadStatus = 'info';
          this.msg = '';
        }, 1000);
      })
        .catch((e) => {
          if (e) {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              setTimeout(() => {
                this.$router.push('/student');
              }, 2500);
            }
          } else {
            this.loadStatus = 'fail';
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
grad1 = #2C69EA;
grad2 = #2961D9;

.soloMsg {
  border-radius: 8px !important;
  background: linear-gradient(to bottom, grad1, grad2);
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    color: white;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    margin: 0 0 1rem 0;
  }
}

.msg_top_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.msg_image {
  margin: 1rem 0 0 0;

  img {
    width: 60px;
  }
}

.msg_no {
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;

  span {
    font-weight: 400;
    font-size: 0.9rem;
    text-transform: uppercase;
    display: block;
  }
}

.msg_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  width: 80%;
  margin: 0 auto;
}

.notification_card {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: center;
  padding: 1.5rem;

  div {
    align-items: center;
    text-align: center;
  }

  p {
    color: title;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    margin: 0 0 0.5rem 0;
  }

  span {
    color: subtitle;
    font-size: 1rem !important;
    font-weight: 400 !important;
    margin: 0 0 2rem 0;
  }
}
</style>
