<template>
  <section>
    <VCard class="std_update_main elevation-20">
      <section class="std_update_container">
        <div class="std_update_top">
          <div>
            <p class="std_title">
              Student Information
            </p>
          </div>
          <div>
            <p class="std_subtitle">
              Update Student Information
            </p>
          </div>
        </div>
        <div class="std_update_grid">
          <div class="std_update_span1_only">
            <p class="std_subtitle">
              Current Class
            </p>
            <VSelect
              v-model="data.basic.class"
              :items="selectClass"
              :hide-details="true"
              label="Admission Class"
              class="std_main_txt pt-2"
              placeholder="LKG / 12"
              :rules="[rules.required]"
              flat
              solo
            />
          </div>
          <div class="std_update_span1_only">
            <p class="std_subtitle">
              Admission Status
            </p>
            <VSelect
              v-model="data.admission.admStatus"
              :items="selectStatus"
              :hide-details="true"
              label="Admission Class"
              class="std_main_txt pt-2"
              placeholder="LKG / 12"
              :rules="[rules.required]"
              flat
              solo
            />
          </div>
          <div class="std_update_span2">
            <p class="std_subtitle">
              Parent/Guardian
            </p>
            <VTextField
              v-model="data.contact.parentName"
              :rules="[rules.required]"
              :value="data.contact.parentName"
              :hide-details="true"
              label="Parent or Guardian"
              placeholder="Parent / Guardian"
              class="std_main_txt pt-2"
              solo
              flat
            />
          </div>

          <div class="std_update_span2_only">
            <p class="std_subtitle">
              Phone No. (P)
            </p>
            <VTextField
              v-model="data.contact.phonePrimary"
              :rules="[rules.required, rules.phoneNumber, rules.phoneNumberLimit]"
              :value="data.contact.phonePrimary"
              :hide-details="true"
              maxlength="10"
              prefix="+91"
              label="Phone No.(Primary)"
              placeholder="0123456789"
              class="std_main_txt pt-2"
              solo
              flat
            />
          </div>
          <div class="std_update_span2_only">
            <p class="std_subtitle">
              Phone No. (S)
            </p>
            <VTextField
              v-model="data.contact.phoneSecondary"
              :rules="[rules.required, rules.phoneNumber, rules.phoneNumberLimit]"
              :value="data.contact.phoneSecondary"
              :hide-details="true"
              maxlength="10"
              prefix="+91"
              label="Phone No.(Secondary)"
              placeholder="0123456789"
              class="std_main_txt pt-2"
              solo
              flat
            />
          </div>
          <div class="std_update_span2">
            <p class="std_subtitle">
              Email
            </p>
            <VTextField
              v-model="data.contact.email"
              :value="data.contact.email"
              :hide-details="true"
              :rules="[rules.required, rules.email]"
              label="Email"
              placeholder="hello@example.com"
              class="std_main_txt pt-2"
              solo
              flat
            />
          </div>
          <div class="std_update_span3">
            <p class="std_subtitle">
              Address
            </p>
            <VTextarea
              v-model="data.contact.address"
              :value="data.contact.address"
              :hide-details="true"
              :rules="[rules.required]"
              label="Address"
              placeholder="38 Park Road, Barrackpore Cantonment, Barrackpore, Kolkata 700120"
              class="std_main_txt"
              single-line
              flat
              solo
            />
          </div>
        </div>
        <div class="std_update_btn">
          <VBtn
            block
            round
            depressed
            color="white"
            @click="close"
          >
            Close
          </VBtn>
          <VBtn
            block
            round
            depressed
            color="white"
            @click="toUpdate = true"
          >
            Save Changes
          </vbtn>
        </div>
      </section>
    </VCard>
    <VDialog
      v-model="toUpdate"
      width="300"
      persistent
    >
      <ConfirmAction
        @confirm="save()"
        @close="toUpdate = false"
      />
    </VDialog>
    <VDialog
      v-model="isUpdating"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'updating'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Updating Student, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'updated'"
        class="notification_card elevation-20"
      >
        <p>Updated</p>
        <span>Student Data Updated</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../conf/api';
import ConfirmAction from './confirmAction.vue';

export default {
  components: {
    ConfirmAction,
  },
  props: ['data'],
  data: () => ({
    toUpdate: false,
    isUpdating: false,
    loadStatus: '',
    selectClass: [],
    selectStatus: [
      'Enrolled',
      'Withdrawn',
    ],
    rules: {
      email:
        (val) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(val) || 'Invalid e-mail.';
        },
      phoneNumber:
        (val) => {
          const phNoPattern = /[0-9]/;
          return phNoPattern.test(val) || 'Invalid phone number.';
        },
      phoneNumberLimit: val => val.length === 10 || 'Invalid phone number.',
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
  }),
  async beforeMount() {
    await axios.get(`${api}/settings/classes`).then((res) => {
      this.selectClass = res.data.data;
    });
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    save() {
      this.loadStatus = 'updating';
      this.isUpdating = true;
      const { id } = this.$route.params;
      const studentSchema = {
        basic: {
          class: this.data.basic.class,
        },
        admission: {
          admStatus: this.data.admission.admStatus,
        },
        contact: {
          parentName: this.data.contact.parentName,
          phonePrimary: this.data.contact.phonePrimary,
          phoneSecondary: this.data.contact.phoneSecondary,
          email: this.data.contact.email,
          address: this.data.contact.address,
        },
      };
      axios.post(`${api}/student/update/${id}`, studentSchema).then(() => {
        this.loadStatus = 'updated';
        setTimeout(() => {
          this.isUpdating = false;
        }, 2500);
        setTimeout(() => {
          this.toUpdate = false;
        }, 2700);
        setTimeout(() => {
          this.$emit('close');
        }, 2900);
      }).catch((e) => {
        if (e) {
          if (e.code === 'auth/network-request-failed') {
            this.loadStatus = 'netfail';
            setTimeout(() => {
              this.$router.push('/student');
            }, 5000);
          }
        } else {
          this.loadStatus = 'fail';
        }
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
grad1 = #2C69EA;
grad2 = #2961D9;

.std_title {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 !important;
}

.std_subtitle {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 !important;
  color: #ffffff;
}

.std_update_main {
  background: linear-gradient(to bottom, grad1, grad2);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.std_update_top {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 2rem 0;
  position: relative;
}

.std_update_container {
  padding: 1rem;
  max-width: 1300px;
  margin: 0 auto;
}

.std_update_grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 360px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.std_update_span1_only {
  grid-column: span 1 / auto;
}

.std_update_span2_only {
  @media screen and (max-width: 360px) {
    grid-column: span 2 / auto;
  }
}

.std_update_span2 {
  @media screen and (max-width: 768px) {
    grid-column: span 2 / auto;
  }

  @media screen and (max-width: 620px) {
    grid-column: span 2 / auto;
  }

  @media screen and (max-width: 360px) {
    grid-column: span 2 / auto;
  }
}

.std_update_span3 {
  grid-column: span 3 / auto;

  @media screen and (max-width: 768px) {
    grid-column: span 4 / auto;
  }

  @media screen and (max-width: 620px) {
    grid-column: span 2 / auto;
  }

  @media screen and (max-width: 360px) {
    grid-column: span 2 / auto;
  }
}

.std_update_btn {
  margin: 1rem 0 0 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.notification_card {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: center;
  padding: 1.5rem;

  div {
    align-items: center;
    text-align: center;
  }

  p {
    color: title;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    margin: 0 0 0.5rem 0;
  }

  span {
    color: subtitle;
    font-size: 1rem !important;
    font-weight: 400 !important;
    margin: 0 0 2rem 0;
  }
}

.notification_ico {
  min-height: 50px;
  max-height: 50px;
  padding: 0;
  margin: 0;
}
</style>
