<template>
  <section>
    <div>
      <VDialog
        v-model="msgSolo"
        width="300"
        persistent
      >
        <MsgSolo
          :title="std.basic.name"
          :phone="msgNo"
          @close="closeMsg"
        />
      </VDialog>
    </div>
    <div>
      <VDialog
        v-model="updateStudent"
        width="300"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <UpdateStudent
          :data="std"
          @close="closeUpdateStudent"
        />
      </VDialog>
    </div>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="top">
        <div>
          <p class="std_title">
            Student Information
          </p>
        </div>
        <div>
          <p class="std_subtitle">
            {{ std.basic.name }}
          </p>
          <p class="std_subtitle">
            Class: {{ std.basic.class }}
          </p>
        </div>
        <div class="top_btn">
          <VBtn
            icon
            large
            class="shadow mr-0 hidden-md-and-up"
            color="white"
            @click="call(std.contact.phonePrimary)"
          >
            <VIcon>
              call
            </VIcon>
          </VBtn>
          <VBtn
            icon
            large
            class="shadow mr-0"
            color="white"
            @click="msg(std.contact.phonePrimary)"
          >
            <VIcon>
              message
            </VIcon>
          </VBtn>
          <VBtn
            icon
            large
            class="shadow"
            color="white"
            @click="updateStudent = true"
          >
            <VIcon>
              edit
            </VIcon>
          </VBtn>
        </div>
      </section>
      <section class="content">
        <div class="grid">
          <div>
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Personal
              </p>
              <div class="std_content">
                <div class="span2">
                  <p class="std_main_subtitle">
                    Name
                  </p>
                  <p class="std_main_txt">
                    {{ std.basic.name }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Gender
                  </p>
                  <p class="std_main_txt">
                    {{ std.basic.gender }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Category
                  </p>
                  <p class="std_main_txt">
                    {{ std.basic.category }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Date Of Birth
                  </p>
                  <p class="std_main_txt">
                    {{ std.basic.dob }}
                  </p>
                </div>
              </div>
            </VCard>
          </div>
          <div>
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Admission
              </p>
              <div class="std_content">
                <div>
                  <p class="std_main_subtitle">
                    Number
                  </p>
                  <p class="std_main_txt">
                    {{ std.admission.admNo }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Date
                  </p>
                  <p class="std_main_txt">
                    {{ std.admission.admDate }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Status
                  </p>
                  <p class="std_main_txt">
                    {{ std.admission.admStatus }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Class
                  </p>
                  <p class="std_main_txt">
                    {{ std.admission.admClass }}
                  </p>
                </div>
              </div>
            </VCard>
          </div>
          <div class="span-row-2 order3">
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Admission Billing
              </p>
              <div class="std_content">
                <div>
                  <p class="std_main_subtitle">
                    Registration
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.registration }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Annual
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.annual }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Admission
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.admission }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Tuition
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.tuition }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Development
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.development }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Caution
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.caution }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Total
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.total }}
                  </p>
                </div>
                <div>
                  <p class="std_main_subtitle">
                    Method
                  </p>
                  <p class="std_main_txt">
                    {{ std.billing.method.text }}
                  </p>
                </div>
                <!-- if CHEQUE Payment -->
                <div v-show="std.billing.method.value === 'cheque'">
                  <p class="std_main_subtitle">
                    Cheque No.
                  </p>
                  <p class="std_main_txt">
                    {{ std.billingCheque.cqNo }}
                  </p>
                </div>
                <div v-show="std.billing.method.value === 'cheque'">
                  <p class="std_main_subtitle">
                    Bank
                  </p>
                  <p class="std_main_txt">
                    {{ std.billingCheque.cqBank }}
                  </p>
                </div>
              </div>
            </VCard>
          </div>
          <div class="span-col-2">
            <VCard class="std_card">
              <p class="std_main_title pb-3">
                Contact
              </p>
              <div class="std_address">
                <div class="span2">
                  <p class="std_main_subtitle">
                    Parent/Guardian
                  </p>
                  <p class="std_main_txt">
                    {{ std.contact.parentName }}
                  </p>
                </div>
                <div class="span2">
                  <p class="std_main_subtitle">
                    Phone No. (P)
                  </p>
                  <p class="std_main_txt">
                    +91-{{ std.contact.phonePrimary }}
                  </p>
                </div>
                <div class="span2">
                  <p class="std_main_subtitle">
                    Phone No. (S)
                  </p>
                  <p class="std_main_txt">
                    +91-{{ std.contact.phoneSecondary }}
                  </p>
                </div>
                <div class="span2">
                  <p class="std_main_subtitle">
                    Email
                  </p>
                  <p class="std_main_txt">
                    {{ std.contact.email }}
                  </p>
                </div>
                <div class="span4">
                  <p class="std_main_subtitle">
                    Address
                  </p>
                  <p class="std_main_txt">
                    {{ std.contact.address }}
                  </p>
                </div>
              </div>
            </VCard>
          </div>
        </div>
      </section>
    </section>
    <div>
      <VDialog
        v-model="isLoading"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_fs elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="white"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_fs elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_fs elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';
import MsgSolo from '../../components/msgSolo.vue';
import UpdateStudent from '../../components/updateStudent.vue';

export default {
  components: {
    MsgSolo,
    UpdateStudent,
  },
  data: () => ({
    isLoading: true,
    loadStatus: '',
    retry: 0,
    noResult: false,
    msgSolo: false,
    updateStudent: false,
    msgNo: '',
    std: {
      basic: {
        name: '',
        gender: '',
        category: '',
        dob: '',
        class: '',
      },
      admission: {
        admNo: '',
        admDate: '',
        admStatus: '',
        admClass: '',
      },
      contact: {
        parentName: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        address: '',
      },
      billing: {
        registration: '',
        annual: '',
        admission: '',
        tuition: '',
        development: '',
        caution: '',
        total: '',
        method: '',
      },
      billingCheque: {
        cqNo: '',
        cqBank: '',
      },
    },
  }),
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      const { id } = this.$route.params;
      await axios.get(`${api}/student/${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.std = res.data.data;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
      this.isLoading = false;
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    call(number) {
      window.location = `tel:${number}`;
    },
    msg(id) {
      this.msgSolo = true;
      this.msgNo = id;
    },
    closeMsg() {
      this.msgSolo = false;
    },
    closeUpdateStudent() {
      this.updateStudent = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Student/view';
</style>
